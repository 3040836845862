module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Architus Docs","description":"General purpose Discord bot supporting advanced role management, custom emotes for non-nitro users, configurable response commands, and more.","short_name":"Architus Docs","homepage_url":"https://docs.archit.us","start_url":"/","background_color":"#496D8F","theme_color":"#6496c4","display":"minimal-ui","icons":[{"src":"/img/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/img/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/img/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"7f875840-f501-5ed3-9dd5-4150353f494d","name":"gatsby-remark-images","version":"3.3.25","pluginOptions":{"plugins":[],"maxWidth":1500,"withWebp":true,"backgroundColor":"#496D8F","linkImagesToOriginal":true},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md"],"gatsbyRemarkPlugins":["gatsby-remark-smartypants","gatsby-remark-slug","gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-embed-snippet","options":{"directory":"/opt/build/repo/docs/content/"}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1500,"withWebp":true,"backgroundColor":"#496D8F","linkImagesToOriginal":true}}]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1500,"withWebp":true,"backgroundColor":"#496D8F","linkImagesToOriginal":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
